import { computed, ref, toRaw, unref } from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useAxios } from '@/composables/useAxios'
import { auth } from '@/plugins/firebase'
import { useStore } from 'vuex'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { noCache } from '@/composables/utils/noCache'

const postHasDesign = (post: {creative: { thumbnail: string }, thumbnail: string}) => {
  const {thumbnail, creative} = post
  const result = creative?.thumbnail || thumbnail
  return !!result
}

const getDesign = (post: {creative: { medias: string[] }, thumbnail: string}) => {
  const { creative, thumbnail } = unref(post)
  const design = creative && creative.medias.length ?
    creative.medias.map(media => noCache(media)) :
    [thumbnail]

  return design.filter(item => !!item)
}

const getThumbnail = (post: {creative: { thumbnail: string }, thumbnail: string, thumbnails: { small: string}}) => {
  let output = null
  const { creative, thumbnail, thumbnails } = unref(post)
  if (creative?.thumbnail) {
    output = creative.thumbnail
  } else if (thumbnails && thumbnails['small']) {
    output = thumbnails['small']
  } else if (thumbnail) {
    output = thumbnail
  }
  return output ? noCache(output) : null
}

const getMediaType = (post: {creative: { mediaType: string, medias: string[] }}) => {
  const { creative } = unref(post)
  let mediaType = 'image'
  
  if (creative && creative.mediaType === 'video') {
    mediaType = 'video'
  }

  if (creative && creative.medias.length > 1) {
    mediaType = 'carrousel'
  }
  return mediaType
}

const getSupportedDestinations = (post: {content: string, thumbnail: string | null, mediaType: string | null, hashtags: string[]}) => {
  const {content, thumbnail, mediaType, hashtags} = post

  const hasDesign = !!thumbnail

  const characters = (content && content.trim().length || 0) + (hashtags.join(' ').trim().length || 0)

  const validateInstagram = () => {
    const validMediaType = !!mediaType && ['image', 'video', 'carrousel'].includes(mediaType)
    const validCharacters = characters <= 2200
    const validDesign = !!hasDesign || mediaType === 'video'
    return validMediaType && validCharacters && validDesign
  }

  const validateInstagramStory = () => {
    const validMediaType = !!mediaType && ['image', 'video', 'carrousel'].includes(mediaType)
    const validCharacters = characters <= 2200
    const validDesign = !!hasDesign || mediaType === 'video'
    return validMediaType && validCharacters && validDesign
  }

  const validateFacebook = () => {
    const validMediaType = !!mediaType && ['image', 'video'].includes(mediaType)
    const validCharacters = !!characters
    const validDesign = !!hasDesign
    return validCharacters || (validMediaType  && validDesign)
  }

  const validateTwitter = () => {
    const validMediaType = !!mediaType && ['image'].includes(mediaType)
    const validCharacters = characters <= 280
    const validDesign = !!hasDesign
    return validCharacters && (validDesign ? validMediaType && validDesign : true)
  }

  const validateLinkedin = () => {
    const validMediaType = !!mediaType && ['image'].includes(mediaType)
    const validCharacters = characters <= 3000
    const validDesign = !!hasDesign
    return validCharacters && (validDesign ? validMediaType && validDesign : true)
  }

  const validateTiktok = () => {
    const validMediaType = !!mediaType && ['video'].includes(mediaType)
    const validCharacters = characters <= 150
    const validDesign = !!hasDesign
    return validCharacters || (validMediaType  && validDesign)
  }

  const validateYoutube = () => {
    const validMediaType = !!mediaType && ['video'].includes(mediaType)
    const validCharacters = characters <= 100
    const validDesign = !!hasDesign
    return validCharacters || (validMediaType  && validDesign)
  }

  return {
    instagram: validateInstagram(),
    'instagram-story': validateInstagramStory(),
    facebook: validateFacebook(),
    twitter: validateTwitter(),
    linkedin: validateLinkedin(),
    tiktok: validateTiktok(),
    youtube: validateYoutube(),
  }
}

export const useFreePosts = () => {
  const { profile } = useProfile()

  const isPostsLoaded = ref(false)

  const isLoading = ref(false)

  const posts = ref<any[]>([])

  const scrollId = ref<string | null>(null)

  const fetchPosts = async (params = {}) => {
    try {
      const firstLoad = !('scrollId' in params)

      isPostsLoaded.value = !firstLoad
      isLoading.value = true

      const requestData = {
        profile: unref(profile).id,
        user: auth.currentUser?.uid,
        ...params
      }

      const { httpFunctions } = await useAxios()

      const { data } = await httpFunctions.post('/publications/free-content/', requestData)

      if (data.publications) {
        if (Array.isArray(data.publications.items)) {
          if (firstLoad) {
            posts.value = data.publications.items
          } else {
            posts.value = [...posts.value, ...data.publications.items]
          }
          
          if ('scrollId' in data.publications && data.publications.items.length) {
            scrollId.value = data.publications.scrollId
          } else {
            scrollId.value = null
          }
        }

      }

    } catch (error) {
      console.log(error)
    } finally {
      isPostsLoaded.value = true
      isLoading.value = false
    }
  }

  return {
    isLoading,
    isPostsLoaded,
    fetchPosts,
    posts,
    scrollId
  }
}

export const useMyPosts = () => {
  const store = useStore()

  const { profile } = useProfile()

  const posts = computed(() => {
    const posts =  toRaw(store.getters['post/myPosts'])
    return posts || []
  })

  const preview = computed(() => {
    const posts =  toRaw(store.getters['post/preview'])
    return posts || []
  })

  const isPostsLoaded = ref(false)

  const fetchPosts = async (filters = {}) => {
    try {
      await store.dispatch('post/fetchMyPosts', unref(profile).id)
      isPostsLoaded.value = true
    } catch (error) {
      console.log(error)
      isPostsLoaded.value = false
    }
  }

  const clearMyPosts = () => {
    store.commit('post/clearMyPosts')
  }

  return {
    isPostsLoaded,
    fetchPosts,
    clearMyPosts,
    posts,
    preview
  }
}

export const usePost = () => {
  const store = useStore()

  const { t } = useI18n()

  const router = useRouter()
  
  const { profile } = useProfile()

  const isWorking = ref(false)

  const post = computed(() => {
    return toRaw(store.getters['post/current'])
  })

  const hasUnsavedData = computed(() => {
    return toRaw(store.getters['post/hasUnsavedData'])
  })

  const hasContent = computed(() => {
    return unref(isPostLoaded) && (!!unref(post).content || unref(post).hashtags.length)
  })

  const hasDesign = computed(() => {
    return unref(isPostLoaded) && postHasDesign(unref(post))
  })

  const design = computed(() => {
    return unref(isPostLoaded) ?
      getDesign(unref(post)) :
      null
  })

  const thumbnail = computed(() => {
    return unref(isPostLoaded) ?
      getThumbnail(unref(post)) :
      null
  })

  const mediaType = computed(() => {
    return unref(isPostLoaded) ?
      getMediaType(unref(post)) :
      null
  })

  const isPostLoaded = computed(() => {
    return unref(post) && !!Object.keys(unref(post)).length
  })

  const supportedDestinations = computed(() => {
    return getSupportedDestinations({
      content: unref(post).content,
      thumbnail: thumbnail.value,
      mediaType: mediaType.value,
      hashtags: unref(post).hashtags
    })
  })

  const isLoadingCreative = computed(() => {
    return store.state.post.isLoadingCreative
  })

  const createPost = async (props?: object) => {
    try {
      isWorking.value = true
      await store.dispatch('post/create', {
        profile: unref(profile).id,
        user: auth.currentUser?.uid,
        ...(props && {props})
      })
    } catch (error) {
      console.log(error)
    }
    isWorking.value = false
  }

  const fetchPost = async (id: string) => {
    try {
      isWorking.value = true
      await store.dispatch('post/fetch', id)
    } catch (error) {
      console.log(error)
    }
    isWorking.value = false
  }

  const updatePost = (payload: object) => {
    store.commit('post/update', { ...unref(post), ...payload })
  }

  const savePost = async () => {
    if (unref(isPostLoaded)) {
      try {
        isWorking.value = true
        await store.dispatch('post/save')
        isWorking.value = false
        useToast.fire({
          icon: 'success',
          title: t('state.save_content.title'),
          text: t('state.save_content.message')
        })
      } catch (error) {
        console.log(error)
        isWorking.value = false
        useToast.fire({
          icon: 'error',
          title: t('state.save_content.error_title'),
          text: t('state.save_content.error_message')
        })
      }
    }
  }

  const deletePost = async () => {
    if (unref(isPostLoaded)) {
      try {
        isWorking.value = true
        useToast.fire({
          icon: 'info',
          title: t('state.remove_content_started.success_title'),
          text: t('state.remove_content_started.success_message')
        })
        await store.dispatch('post/remove')
        isWorking.value = false
        useToast.fire({
          icon: 'success',
          title: t('state.remove_content.success_title'),
          text: t('state.remove_content.success_message')
        })
        router.replace({ name: 'my-posts' })
      } catch (error) {
        console.log(error)
        isWorking.value = false
        useToast.fire({
          icon: 'error',
          title: t('state.remove_content.error_title'),
          text: t('state.remove_content.error_message')
        })
      }
    }
  }

  const clearStore = () => {
    store.commit('post/clearStore')
  }

  return {
    isPostLoaded,
    isWorking,
    hasUnsavedData,
    hasContent,
    thumbnail,
    mediaType,
    hasDesign,
    design,
    supportedDestinations,
    isLoadingCreative,
    fetchPost,
    createPost,
    updatePost,
    savePost,
    deletePost,
    clearStore,
    post
  }
}

export const useReviewPosts = () => {
  const { profile } = useProfile()

  const isPostsLoaded = ref(false)

  const posts = ref([])

  const scrollId = ref<string | null>(null)

  const fetchPosts = async (filters = {}) => {
    try {
      isPostsLoaded.value = false

      const requestData = {
        profile: unref(profile).id,
        scrollId: null,
        ...filters
      }

      const { httpFunctions } = await useAxios()

      const { data } = await httpFunctions.post('/publications/reviews/', requestData)

      if (data.publications) {
        if (Array.isArray(data.publications)) {
          posts.value = data.publications
        }

        if ('scrollId' in data.publications) {
          scrollId.value = data.publications.scrollId
        }
      }

      isPostsLoaded.value = true
    } catch (error) {
      console.log(error)
    }
  }

  return {
    isPostsLoaded,
    fetchPosts,
    posts
  }
}

export const useApprove = (publicationId: string, useVistaCreate: boolean) => {
  const { profile } = useProfile()

  const { t } = useI18n()

  const isApproved = ref(false)

  const isLoading = ref(false)

  const approve = async () => {
    isLoading.value = true

    try {
      const { httpFunctions } = await useAxios()

      const requestData = {
        profile: unref(profile).id,
        publication: publicationId,
        ...(useVistaCreate && {publicationId})
      }

      const endpoint = useVistaCreate ? '/publication-requests/approve/' : '/publications/use/'

      const request = await httpFunctions.post(endpoint, requestData)

      if (request.status !== 201) {
        throw new Error("Content not approved")
      }

      isApproved.value = true

      useToast.fire({
        icon: 'success',
        title: t('state.approve_content.title'),
        text: t('state.approve_content.message')
      })
    } catch (error) {
      useToast.fire({
        icon: 'error',
        title: t('state.approve_content.error_title'),
        text: t('state.approve_content.error_message')
      })
      console.error({ error })
    }

    isLoading.value = false
  }

  return {
    isApproved,
    isLoading,
    approve,
  }
}

export const useReject = (publicationId: string) => {
  const { profile } = useProfile()

  const { t } = useI18n()

  const isRejected = ref(false)

  const isLoading = ref(false)

  const reject = async (rejection: any) => {
    isLoading.value = true

    try {
      const payload = {
        profileId: unref(profile).id,
        publicationId: publicationId,
        motive: rejection.reason,
        feedback: rejection.feedback,
        category: unref(profile).category.name,
        keywords: unref(profile).keywords,
        rejectionDate: new Date().toLocaleString()
      }

      const { httpFunctions } = await useAxios()

      const requestData = {
        profile: unref(profile).id,
        publication: publicationId,
        rejectionData: payload
      }

      await httpFunctions.post('/publications/reject/', requestData)

      isRejected.value = true

      useToast.fire({
        icon: 'success',
        title: t('state.reject_content.title'),
        text: t('state.reject_content.message')
      })
    } catch (error) {
      useToast.fire({
        icon: 'error',
        title: t('state.reject_content.error_title'),
        text: t('state.reject_content.error_message')
      })
      console.error({ error })
    }

    isLoading.value = false
  }

  return {
    isRejected,
    isLoading,
    reject,
  }
}

export const useRestoreMedia = () => {
  const store = useStore()

  const { t } = useI18n()

  const isWorking = ref(false)
  const isFetchingImage = ref(false)
  const media = ref(null)

  const post = computed(() => {
    return toRaw(store.getters['post/current'])
  })

  const isPostLoaded = computed(() => {
    return unref(post) && !!Object.keys(unref(post)).length
  })

  const hasOriginalPublication = computed(() => {
    return unref(isPostLoaded) && unref(post).publicationUID
  })

  const hasThumbnail = computed(() => {
    return 'creative' in unref(post) ||  'thumbnail' in unref(post)
  })

  const getMedia = async () => {
    if (unref(hasOriginalPublication)) {
      try {
        isFetchingImage.value = true
        const endPoint = 'creative' in unref(post) ? '/publication-requests/thumbnail' : '/publications/original-image'

        const { httpFunctions } = await useAxios()
        const { data } = await httpFunctions.get(`${endPoint}/${unref(post).publicationUID}`)
        media.value = data
      } catch (error) {
        console.log(error)
      }
      isFetchingImage.value = false
    }
  }

  const restoreMedia = async () => {
    if (unref(isPostLoaded) && unref(post).uid) {
      try {
        isWorking.value = true
        const endPoint = 'creative' in unref(post) ? '/content-requests/restore-creative' : '/publications/restore-image'

        const { httpFunctions } = await useAxios()
        await httpFunctions.post(`${endPoint}/`, {
          ...(('creative' in unref(post)) && {contentId: unref(post).uid}),
          ...(!('creative' in unref(post)) && {publicationId: unref(post).uid}),
        })
        await store.dispatch('post/fetch', unref(post).uid)
        isWorking.value = false

        useToast.fire({
          icon: 'success',
          title: t('state.reset_image.title'),
          text: t('state.reset_image.message')
        })

        return true
      } catch (error) {
        console.log(error)
        isWorking.value = false

        useToast.fire({
          icon: 'error',
          title: t('state.reset_image.error_title'),
          text: t('state.reset_image.error_message')
        })

        return false
      }
    }
  }

  return {
    hasOriginalPublication,
    media,
    isWorking,
    isFetchingImage,
    hasThumbnail,
    getMedia,
    restoreMedia,
  }

}

export const useCreative = (contentId: string) => {
  const store = useStore()

  const update = async (
    // contentId: string,
    creativeId: string,
    downloadUrl: string,
    downloadExtension: 'jpg' | 'jpeg' | 'png' | 'gif' | 'mp4' | 'zip',
    format: 'jpg' | 'jpeg' | 'png' | 'gif' | 'mp4',
    width: number,
    height: number,
  ) => {
    const { httpFunctions } = await useAxios()

    try {
      store.commit('post/setIsLoadingCreative', true)
  
      const response = await httpFunctions.post('/content-requests/creative', { contentId, creativeId, downloadUrl, downloadExtension, format, width, height })
  
      store.commit('post/update', { 
        ...toRaw(store.getters['post/current']),
        creative: response.data
      })
  
      store.commit('post/setHasUnsavedData', false)
    } catch (error) {
      console.log(error)
    }

    store.commit('post/setIsLoadingCreative', false)
  }

  return {
    update
  }
}

export const usePostValidator = (post: {content: string, thumbnail: string, creative: { mediaType: string; medias: string[]; }, hashtags: string[]}) => {
  let mediaType = getMediaType(post)
  const sources = getSupportedDestinations({...post, mediaType})

  return { sources }
}
